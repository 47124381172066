/* eslint-disable react/no-danger */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import * as sH from 'sanitize-html'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ReactPlayer from 'react-player'
import { Transition } from '@headlessui/react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Parallax } from 'react-scroll-parallax'
import { breakpoints } from '../../utils/breakpoints.js'

const VideoContainer = styled.div`
  overflow: hidden !important;
  position: relative;
  width: 100% !important;
  height: 56.25vw !important;
  max-height: 900px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #a01929;
    opacity: 0.65;
    z-index: 5;
  }
  @media (max-width: ${breakpoints.md}px) {
    overflow: hidden !important;
    height: 90vh !important;
    width: 400vw !important;
    width: auto !important;
  }
  .videoPlayer {
    mix-blend-mode: overlay;
    width: 100vw !important;
    height: 56.25vw !important;
    position: absolute;
    z-index: 1;
    @media (max-width: ${breakpoints.md}px) {
      overflow: hidden !important;
      width: 400vw !important;
      height: 90vh !important;
    }
  }
  .image-block {
    width: 100vw !important;
    height: 56.25vw !important;
    position: absolute;
    z-index: 0;
    @media (max-width: ${breakpoints.md}px) {
      overflow-x: hidden !important;
      width: 160vh !important;
      height: 90vh !important;
    }
  }
`
const Wrapper = styled.section`
  ${tw`w-full h-full relative mb-12 md:mt-[-54px]`}
`
const Content = styled.section`
  ${tw`mt-8 z-20 absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 max-w-7xl w-full h-full flex flex-col text-white md:grid md:grid-cols-2 items-center justify-end px-8`}
`
const Title = styled.h1`
  ${tw`text-[58px] md:text-6xl lg:text-7xl xl:text-8xl mb-5 !leading-[0.95] font-bold`}
`
const Subtitle = styled.p`
  ${tw`antialiased text-2xl leading-snug max-w-md`}
`
const Image = styled(props => <GatsbyImage {...props} />)`
  ${tw`h-[35vh] md:h-[50vw] lg:h-[45vw] xl:h-[45vw] ml-[90px] lg:mr-[16px] xl:mr-0 md:ml-[32px] lg:ml-[70px] 2xl:ml-0 2xl:translate-x-[70px] max-h-[720px] md:mt-[13vw] lg:mt-[13vw] xl:mt-[13vw] 2xl:mt-[190px]`}
`
const Hero = ({ data }) => {
  const { title, subtitle, video, image } = data

  // eslint-disable-next-line no-unused-vars
  const [started, setStarted] = useState(false)

  const aniOpen = 'opacity-0'
  const aniClose = 'opacity-100'
  const aniClass = 'h-full w-full bg-primary z-10 transition-opacity ease-linear duration-500'

  const animationProps = {
    show: true,
    className: aniClass,
    enterFrom: aniClose,
    enterTo: aniOpen,
    entered: aniOpen,
  }

  const imageGatsbyData = getImage(image?.localFile.childImageSharp.gatsbyImageData)

  return (
    <Wrapper>
      <Content>
        <div className="w-full">
          {title && <Title dangerouslySetInnerHTML={{ __html: sH(title) }} />}
          {subtitle && <Subtitle dangerouslySetInnerHTML={{ __html: sH(subtitle) }} />}
        </div>
        <Parallax speed={10}>
          <Image className="shadow-[0_0_15px_0px_rgba(0,0,0,0.2)]" image={imageGatsbyData} alt={title} />
        </Parallax>
      </Content>
      <VideoContainer
        style={{
          backgroundColor: `#A01929`,
          pointerEvents: `none`,
        }}
      >
        <Transition {...animationProps} />
        <ReactPlayer
          className="videoPlayer"
          url={video}
          onStart={() => setStarted(true)}
          config={{
            vimeo: {
              playerOptions: {},
            },
            youtube: {
              playerVars: {
                controls: 0,
                showinfo: 0,
                disablekb: 1,
                modestbranding: 1,
              },
            },
          }}
          width="100vw"
          height="56.25vw"
          playing
          loop
          muted
          playsinline
        />
      </VideoContainer>
    </Wrapper>
  )
}

Hero.propTypes = {
  data: PropTypes.object.isRequired,
}

export const projectsData = graphql`
  fragment Hero on WpPage_Pagebuilder_Layouts_Hero {
    fieldGroupName
    subtitle
    title
    video
    image {
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 0.9, width: 700, transformOptions: { cropFocus: ATTENTION })
        }
      }
    }
  }
`

export default Hero
